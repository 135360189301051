import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Table, Button, Spinner, Form, Modal } from 'react-bootstrap';
import './OrderDetails.css';

const OrderDetails = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  
  const [order, setOrder] = useState(null);
  const [cart, setCart] = useState(null);
  const [originalCart, setOriginalCart] = useState(null);
  const [editedItems, setEditedItems] = useState({});
  const [shippingMethod, setShippingMethod] = useState('');
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [editing, setEditing] = useState(false);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showConfirmPickupModal, setShowConfirmPickupModal] = useState(false);
  const [showConfirmAndPayLaterModal, setShowConfirmSendAndPayLaterModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        console.count("fetchData called");

        // Fetch order details
        const orderResponse = await axios.get(`${process.env.REACT_APP_BACKEND_NODE_URL}/api/orders/${id}`, {headers: {"ngrok-skip-browser-warning": true, 'Access-Control-Allow-Origin': "*"}});
        const orderData = orderResponse.data;

        // Fetch cart if cart_id is available
        let cartData = null;
        if (orderData.cart_id) {
          const cartResponse = await axios.get(`${process.env.REACT_APP_BACKEND_NODE_URL}/api/cart/${orderData.cart_id}`,{headers: {"ngrok-skip-browser-warning": true, 'Access-Control-Allow-Origin': "*"}});
          cartData = cartResponse.data;
        }

        // Fetch shipping method
        // let shippingMethodData = '';
        // const shippingResponse = await axios.get(`${process.env.REACT_APP_BACKEND_NODE_URL}/api/orders/${id}/consignments`);
        // if (shippingResponse.data.shipping && shippingResponse.data.shipping.length > 0) {
        //   shippingMethodData = shippingResponse.data.shipping[0].shipping_method;
        // }

        // Update all states at once
        setOrder(orderData);
        setCart(cartData);
        setOriginalCart(cartData);
        setShippingMethod(orderData.consignments && orderData.consignments.shipping && orderData.consignments.shipping.length > 0 ? orderData.consignments.shipping[0].shipping_method : '');
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id, updating]);

  const canConfirmPickup = useMemo(() => {
    return (
      order &&
      (order.payment_method.toLowerCase().includes('pago contra entrega') ||
        order.payment_method.toLowerCase().includes('efectivo contra entrega')) &&
      shippingMethod.toLowerCase().includes('recoger') &&
      location.state?.from === 'preparando pedido' &&
      order.status_id === 9
    );
  }, [order, location.state]);
  
  const canConfirmSendAndPayLater = useMemo(() => {
    return (
      order &&
      (order.payment_method.toLowerCase().includes('pago contra entrega') ||
        order.payment_method.toLowerCase().includes('efectivo contra entrega')) &&
      (shippingMethod === 'Envío a domicilio' || shippingMethod === 'Free Shipping') &&
      location.state?.from === 'preparando pedido' &&
      order.status_id === 9
    );
  }, [order, location.state]);

  const canCaptureFunds = useMemo(() => {
    return (
      order &&
      location.state?.from === 'preparando pedido' &&
      (!order.staff_notes || !order.staff_notes.includes('Stripe Transaction ID')) &&
      !(
        order.payment_method.toLowerCase().includes('pago contra entrega') ||
        order.payment_method.toLowerCase().includes('efectivo contra entrega')
      ) &&
      order.status_id !== 11
    );
  }, [order, location.state]);

  const fetchOrderDetails = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_NODE_URL}/api/orders/${id}`, {headers: {"ngrok-skip-browser-warning": true, 'Access-Control-Allow-Origin': "*"}});
      setOrder(response.data);
  
      if (response.data.cart_id && !cart) {
        await fetchCart(response.data.cart_id);
      }
    } catch (error) {
      console.error('Error fetching order details:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchShippingMethod = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_NODE_URL}/api/orders/${id}/consignments`, {headers: {"ngrok-skip-browser-warning": true, 'Access-Control-Allow-Origin': "*"}});
      if (response.data.shipping && response.data.shipping.length > 0) {
        setShippingMethod(response.data.shipping[0].shipping_method);
      }
    } catch (error) {
      console.error('Error fetching shipping method:', error);
    }
  };

  const fetchCart = async (cartId) => {
    if (!originalCart) {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_NODE_URL}/api/cart/${cartId}`, {headers: {"ngrok-skip-browser-warning": true, 'Access-Control-Allow-Origin': "*"}});
        setCart(response.data);
        setOriginalCart(response.data);
      } catch (error) {
        console.error('Error fetching cart details:', error);
      }
    }
  };

  const handleQuantityChange = (productId, value) => {
    const item = cart.cartItems.find(item => item.product_id === productId);
  
    if (item) {
      const isKg = item.custom_fields && item.custom_fields.tipo === "Kg";
      const newValue = parseFloat(value) || 0;
  
      let updatedItem;
  
      if (isKg) {
        const totalPrice = newValue * (item.sale_price ? item.sale_price : item.non_sale_price);
        updatedItem = {
          ...item,
          selected_weight: newValue,
          price: parseFloat(totalPrice.toFixed(2)), // Asegura 2 decimales
        };
      } else {
        const totalPrice = newValue * (item.sale_price ? item.sale_price : item.non_sale_price);
        updatedItem = {
          ...item,
          quantity: Math.round(newValue),
          price: parseFloat(totalPrice.toFixed(2)),
        };
      }
  
      setEditedItems(prevEditedItems => ({
        ...prevEditedItems,
        [productId]: true,
      }));
  
      setCart(prevCart => {
        const updatedCartItems = prevCart.cartItems.map(cartItem =>
          cartItem.product_id === productId ? updatedItem : cartItem
        );
  
        const newTotal = updatedCartItems.reduce((acc, item) => acc + (item.price || 0), 0);
  
        return {
          ...prevCart,
          cartItems: updatedCartItems,
          total: parseFloat(newTotal.toFixed(2)), // Subtotal con 2 decimales
        };
      });
    }
  };  

  const handleSaveChanges = async () => {
    setUpdating(true);
    try {
      // Filtro para obtener los productos que fueron editados
      const editedItemsArray = cart.cartItems.filter(item => editedItems[item.product_id]);

      // Función para actualizar un producto
      const updateProduct = async (item) => {
        const isKg = item.custom_fields && item.custom_fields.tipo === 'Kg';

        const weightOrQuantity = isKg 
            ? parseFloat(item.selected_weight) 
            : parseFloat(item.quantity);

        const pesokgField = item.custom_fields && item.custom_fields.pesokg 
            ? parseFloat(item.custom_fields.pesokg)
            : null;

        const finalPrice = pesokgField 
            ? parseFloat(item.sale_price ? item.sale_price : item.non_sale_price) * weightOrQuantity 
            : (item.sale_price ? item.sale_price : item.non_sale_price) * weightOrQuantity;

        const customName = isKg
            ? (item.custom_name.toLowerCase().includes("orden de") && item.custom_name.toLowerCase().includes("kg"))
                ? `1 Orden de ${weightOrQuantity.toFixed(3)} kg de ${item.name}`
                : `${item.quantity} Pieza(s) de ${item.name} por un total de ${weightOrQuantity.toFixed(3)} kg`
            : item.custom_name;

        const updatedProduct = {
          ...item,
          ...(isKg ? { selected_weight: weightOrQuantity.toFixed(3) } : { quantity: weightOrQuantity }),
          price_inc_tax: parseFloat(finalPrice).toFixed(2),
          price_ex_tax: parseFloat(finalPrice).toFixed(2),
          custom_name: customName
        };

        // Enviamos la actualización del producto editado
        await axios.put(`${process.env.REACT_APP_BACKEND_NODE_URL}/api/orders/${id}/products/${item.product_id}`, updatedProduct, {headers: {"ngrok-skip-browser-warning": true, 'Access-Control-Allow-Origin': "*"}});
      };

      // Actualizamos cada producto editado secuencialmente
      for (const item of editedItemsArray) {
        await updateProduct(item);
      }

      // Obtener el total de la orden
      // const totalOrderPrice = calculateCartTotal();

      // Enviar el total calculado
      // await axios.put(`${process.env.REACT_APP_BACKEND_NODE_URL}/api/total-order/${id}`, {
      //   totalAmount: totalOrderPrice.toFixed(2)
      // });
      // console.log('sent');

      // Refrescar la orden
      await fetchOrderDetails();
      setEditedItems({});
      setEditing(false);
    } catch (error) {
      console.error('Error updating order products or total:', error);
    } finally {
      setUpdating(false);
    }
  };

  const handleEditToggle = () => {
    setEditing(!editing);
  };

  const handleCancelEdit = () => {
    setCart(originalCart);
    setEditedItems({});
    setEditing(false);
  };

  const handleCaptureFunds = async () => {
    setIsProcessing(true);
    let response;
    try {
      response = await axios.post(`${process.env.REACT_APP_BACKEND_NODE_URL}/api/orders/${id}/capture-funds`,{customerId: order.customer_id}, {headers: {"ngrok-skip-browser-warning": true, 'Access-Control-Allow-Origin': "*"}});
      setIsProcessing(false);
      setShowConfirmModal(false);
      await fetchOrderDetails();

      if (response && response.status === 200 && shippingMethod.includes("Recoger en Sucursal")) {
        await axios.put(`${process.env.REACT_APP_BACKEND_NODE_URL}/api/orders/${id}`, {
          status_id: 8
        }, {headers: {"ngrok-skip-browser-warning": true, 'Access-Control-Allow-Origin': "*"}});
        await fetchOrderDetails();
        setEditing(false);
      }
    } catch (error) {
      console.error('Error capturing funds or updating order status:', error);
      setIsProcessing(false);
    }
  };

  const handleConfirmPickup = async () => {
    setIsProcessing(true);
    let response;
    try {
      response = await axios.put(`${process.env.REACT_APP_BACKEND_NODE_URL}/api/orders/${id}`, {
        status_id: 12
      }, {headers: {"ngrok-skip-browser-warning": true, 'Access-Control-Allow-Origin': "*"}});
      setIsProcessing(false);
      setShowConfirmPickupModal(false);
      await fetchOrderDetails();
    } catch (error) {
      console.error('Error updating order status:', error);
      setIsProcessing(false);
    } finally {
      if (response && response.status === 200) {
        setEditing(false);
      }
    }
  };

  const handleConfirmSendAndPayLater = async () => {
    setIsProcessing(true);
    let response;
    try {
      response = await axios.put(`${process.env.REACT_APP_BACKEND_NODE_URL}/api/orders/${id}`, {
        status_id: 2
      }, {headers: {"ngrok-skip-browser-warning": true, 'Access-Control-Allow-Origin': "*"}});
      setIsProcessing(false);
      setShowConfirmSendAndPayLaterModal(false);
      await fetchOrderDetails();
    } catch (error) {
      console.error('Error updating order status:', error);
      setIsProcessing(false);
    } finally {
      if (response && response.status === 200) {
        setEditing(false);
      }
    }
  };

  const extractScheduledDate = (message) => {
    const regex = /Fecha programada: (\d{2}\/\d{2}\/\d{4})/;
    const match = message.match(regex);
    return match ? match[1] : 'N/A';
  };

  const removeScheduledDate = (message) => {
    const cleanedMessage = message
      .replace(/Fecha programada: \d{2}\/\d{2}\/\d{4}/, '')
      .replace(/^\|? Comentarios del pedido:/, '')
      .trim();
    return cleanedMessage;
  };

  const formatCurrency = (amount, shouldRound = true) => {
    if (shouldRound) {
      return `$${parseFloat(amount).toFixed(2)}`;
    }
    return `$${amount}`;
  };

  const formatCurrencyWithoutSymbol = (amount, shouldRound = true) => {
    if (shouldRound) {
      return parseFloat(amount).toFixed(2);
    }
    return amount.toString();
  };

  const handleBackClick = () => {
    if (location.state && location.state.from) {
      const cleanedPath = location.state.from.replace(/\s+/g, '-').toLowerCase();
      navigate(`/${cleanedPath}`);
    } else {
      navigate('/orders');
    }
  };

  const canEdit = () => {
    return (
      order &&
      location.state?.from === 'preparando pedido' &&
      (!order.staff_notes || !order.staff_notes.includes('Stripe Transaction ID')) &&
      order.status_id === 9
    );
  };

  const getOrderStatus = () => {
    if (order.status === 'Awaiting Fulfillment') {
      return 'En espera de entrega';
    } else if (order.status === 'Awaiting Payment') {
      return 'Orden creada y En espera de cobro';
    } else {
      return order.status;
    }
  };

  const calculateCartTotal = () => {
    return cart.cartItems.reduce((acc, item) => acc + (item.price || 0), 0);
  };

  const handleDownload = () => {
    // -------------------------
    // PRIMER ARCHIVO (orden_{order.id}.txt)
    // -------------------------
    // 1) Primera línea: Apellido
    let content1 = `${order.billing_address.last_name}\r\n`;
  
    // 2) Primera lista de productos (sin "comentarios")
    cart.cartItems.forEach(item => {
      const product = order.products.find(p => p.product_id === item.product_id);
      const sku = product ? product.sku : 'SKU no encontrado';
      const price = formatCurrencyWithoutSymbol(item.non_sale_price);
      const quantity = item.selected_weight || item.quantity;
      // Usamos tabulaciones
      content1 += `${sku}\t${price}\t${quantity}\r\n`;
    });
  
    // Generamos el primer archivo
    const blob1 = new Blob([content1], { type: 'text/plain' });
    const url1 = window.URL.createObjectURL(blob1);
    const a1 = document.createElement('a');
    a1.href = url1;
    a1.download = `orden_${order.id}.txt`;
    document.body.appendChild(a1);
    a1.click();
    a1.remove();
  
    // -------------------------
    // SEGUNDO ARCHIVO (orden_{order.id}_C.txt)
    // -------------------------
    let content2 = '';
  
    // 3) Información de la orden
    content2 += `APP-ORDEN: ${order.id}\r\n`;
    content2 += `Método de Pago: ${order.payment_method}\r\n`;
    content2 += `Fecha solicitada de entrega: ${extractScheduledDate(order.customer_message)}\r\n`;
    content2 += `Método de envío: ${shippingMethod}\r\n`;
    content2 += `Comentario de pedido: ${removeScheduledDate(order.customer_message)}\r\n`;
  
    // 4) Nombre del comprador
    content2 += `${order.billing_address.first_name}\r\n`;
  
    // 5) Segunda lista de productos, con "comentarios"
    cart.cartItems.forEach(item => {
      const product = order.products.find(p => p.product_id === item.product_id);
      const sku = product ? product.sku : 'SKU no encontrado';
      const price = formatCurrencyWithoutSymbol(item.non_sale_price);
      const quantity = item.selected_weight || item.quantity;
      content2 += `${sku}\t${product.name}\t${price}\t${quantity}\tcomentarios\t${item.comment || ''}\r\n`;
    });
  
    // Generamos el segundo archivo
    const blob2 = new Blob([content2], { type: 'text/plain' });
    const url2 = window.URL.createObjectURL(blob2);
    const a2 = document.createElement('a');
    a2.href = url2;
    a2.download = `orden_${order.id}_C.txt`;
    document.body.appendChild(a2);
    a2.click();
    a2.remove();
  };
  
  // -------------------------------------------------------------
  // ORDENAR LOS PRODUCTOS POR SKU (numérico -> alfanumérico -> solo letras)
  // -------------------------------------------------------------
  const sortItemsBySku = (cartItems) => {
    if (!order?.products || !cartItems) return cartItems;

    const numericRegex = /^\d+$/;       // Sólo dígitos
    const alphaRegex   = /^[A-Za-z]+$/; // Sólo letras
    const alphaNumReg  = /^[A-Za-z0-9]+$/; // Alfanumérico (sin caract. especiales)

    // Determina la "categoría" del SKU
    const getCategory = (sku) => {
      if (numericRegex.test(sku)) {
        return 1; // Numérico puro
      } else if (alphaNumReg.test(sku) && !alphaRegex.test(sku)) {
        return 2; // Alfanum
      } else if (alphaRegex.test(sku)) {
        return 3; // Sólo letras
      }
      return 4;   // Otros (con símbolos, etc.)
    };

    const compareSku = (a, b) => {
      const prodA = order.products.find((p) => p.product_id === a.product_id);
      const prodB = order.products.find((p) => p.product_id === b.product_id);

      const skuA = prodA?.sku || '';
      const skuB = prodB?.sku || '';

      const catA = getCategory(skuA);
      const catB = getCategory(skuB);

      if (catA !== catB) {
        return catA - catB; // menor categoría => primero
      } else {
        // misma categoría
        if (catA === 1) {
          // numéricos
          return parseInt(skuA, 10) - parseInt(skuB, 10);
        } else {
          // alfabético
          return skuA.localeCompare(skuB);
        }
      }
    };

    const copy = [...cartItems];
    copy.sort(compareSku);
    return copy;
  };

  // Preparamos array ya ordenado
  const sortedCartItems = cart?.cartItems ? sortItemsBySku(cart.cartItems) : [];

  return (
    <div className="container mt-4">
      {(loading || updating) && (
        <div className="overlay">
          <Spinner animation="border" role="status">
            <span className="sr-only"></span>
          </Spinner>
        </div>
      )}
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h1>
          Detalle de la orden{' '}
          {order ? (
            <>
              {order.id}
              <br />
              <span style={{ fontSize: '0.6em' }}>
                {order.billing_address.first_name} {order.billing_address.last_name}
              </span>
            </>
          ) : (
            ''
          )}
        </h1>
        <div>
          <Button variant="warning" onClick={handleDownload} style={{ marginRight: '12px' }}>
            Descargar
          </Button>
          <Button variant="secondary" onClick={handleBackClick}>
            Regresar
          </Button>
        </div>
      </div>

      <div className="table-container top-table-container">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>SKU</th>
              <th>Nombre del producto</th>
              <th>Precio Unidad</th>
              <th>Cantidad</th>
              <th>Monto Total</th>
              <th>Comentarios</th>
            </tr>
          </thead>
          <tbody>
            {cart &&
              sortedCartItems.map((item) => {
                const product = order.products.find((p) => p.product_id === item.product_id);
                const sku = product ? product.sku : 'SKU no encontrado';
                const pesokgField = product?.custom_fields?.find((f) => f.name === 'pesokg');
                const tipoField = product?.custom_fields?.find(
                  (f) => f.name === 'tipo' && f.value === 'Kg'
                );
                const selectedWeight =
                  item.selected_weight !== undefined && item.selected_weight !== ''
                    ? item.selected_weight
                    : pesokgField?.value || item.quantity || 0;

                const isKg = tipoField && tipoField.value === 'Kg';

                // Si es por peso, el precio por unidad es el precio por kg
                // y el total se multiplica por el peso seleccionado
                const pricePerUnit = item.sale_price ? item.sale_price : item.non_sale_price;

                return (
                  <tr key={item.id}>
                    <td>{sku}</td>
                    <td>{item.custom_name}</td>
                    <td>{formatCurrency(pricePerUnit)}</td>
                    <td>
                      {editing ? (
                        <Form.Control
                          type="number"
                          value={selectedWeight}
                          min="0"
                          step={isKg ? '0.01' : '1'}
                          onChange={(e) => {
                            let value = parseFloat(e.target.value) || 0;
                            if (!isKg) {
                              value = Math.round(value);
                            }
                            handleQuantityChange(item.product_id, value);
                          }}
                        />
                      ) : (
                        selectedWeight
                      )}
                    </td>
                    <td>{formatCurrency(item.price)}</td>
                    <td>{item.comment || ''}</td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
        <div className="d-flex justify-content-end">
          {canEdit() && (
            <>
              {editing ? (
                <>
                  <Button
                    variant="primary"
                    onClick={handleSaveChanges}
                    className="mt-3"
                    style={{ marginRight: '12px' }}
                  >
                    Guardar
                  </Button>
                  <Button variant="secondary" onClick={handleCancelEdit} className="mt-3 ml-2">
                    Cancelar
                  </Button>
                </>
              ) : (
                <Button variant="primary" onClick={handleEditToggle} className="mt-3">
                  Editar
                </Button>
              )}
            </>
          )}
        </div>
      </div>
      <div className="details-table-container">
        <Table striped bordered hover>
          <tbody>
            <tr>
              <td>
                <strong>Estatus:</strong>
              </td>
              <td>{order ? order.custom_status : ''}</td>
            </tr>
            <tr>
              <td>
                <strong>Método de Pago:</strong>
              </td>
              <td>{order ? order.payment_method : ''}</td>
            </tr>
            <tr>
              <td>
                <strong>Fecha solicitada de entrega:</strong>
              </td>
              <td>{order ? extractScheduledDate(order.customer_message) : ''}</td>
            </tr>
            <tr>
              <td>
                <strong>Método de entrega:</strong>
              </td>
              <td>{shippingMethod}</td>
            </tr>
            {order && order.staff_notes && order.staff_notes.includes('Stripe Transaction ID') && (
              <tr>
                <td>
                  <strong>Confirmación de Pago:</strong>
                </td>
                <td>
                  Stripe ID:{' '}
                  {order.staff_notes.match(/Stripe Transaction ID: (\w+)/)?.[1] ||
                    'ID no encontrado'}
                </td>
              </tr>
            )}
            <tr>
              <td>
                <strong>Comentario:</strong>
              </td>
              <td>{order ? removeScheduledDate(order.customer_message) : ''}</td>
            </tr>
            <tr>
              <td>
                <strong>Subtotal:</strong>
              </td>
              <td>{cart && cart.cartTotal ? formatCurrency(cart.cartTotal) : '$0.00'}</td>
            </tr>
            <tr>
              <td>
                <strong>Descuento:</strong>
              </td>
              <td>{order ? `-${formatCurrency(order.discount_amount)}` : '-$0.00'}</td>
            </tr>
            <tr>
              <td>
                <strong>Envío:</strong>
              </td>
              <td>{order ? formatCurrency(order.shipping_cost_inc_tax) : ''}</td>
            </tr>
            <tr>
              <td>
                <strong>Total de la orden:</strong>
              </td>
              <td>{order ? formatCurrency(order.total_inc_tax) : '$0.00'}</td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div className="d-flex justify-content-end">
        {canCaptureFunds && (
          <Button
            variant="success"
            onClick={() => setShowConfirmModal(true)}
            className="mt-3"
            disabled={editing}
          >
            Realizar Cobro
          </Button>
        )}
        {canConfirmPickup && (
          <Button
            variant="primary"
            onClick={() => setShowConfirmPickupModal(true)}
            className="mt-3"
            disabled={editing}
          >
            Confirmar Recolección
          </Button>
        )}
        {canConfirmSendAndPayLater && (
          <Button
            variant="primary"
            onClick={() => setShowConfirmSendAndPayLaterModal(true)}
            className="mt-3"
            disabled={editing}
          >
            Confirmar Envío
          </Button>
        )}
      </div>

      <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmación</Modal.Title>
        </Modal.Header>
        <Modal.Body>¿Seguro que quiere realizar cobro?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
            Cerrar
          </Button>
          <Button
            variant="primary"
            onClick={handleCaptureFunds}
            disabled={isProcessing}
            style={{ backgroundColor: isProcessing ? '#6c757d' : '#007bff' }}
          >
            {isProcessing ? 'Capturando...' : 'Confirmar'}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showConfirmPickupModal} onHide={() => setShowConfirmPickupModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmación</Modal.Title>
        </Modal.Header>
        <Modal.Body>¿Seguro que quiere cambiar el estatus a "Listo y en espera de recolección"?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmPickupModal(false)}>
            Cerrar
          </Button>
          <Button
            variant="primary"
            onClick={handleConfirmPickup}
            disabled={isProcessing}
            style={{ backgroundColor: isProcessing ? '#6c757d' : '#007bff' }}
          >
            {isProcessing ? 'Procesando...' : 'Confirmar'}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showConfirmAndPayLaterModal} onHide={() => setShowConfirmPickupModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmación</Modal.Title>
        </Modal.Header>
        <Modal.Body>¿Seguro que quiere cambiar el estatus a "En Ruta"?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmSendAndPayLaterModal(false)}>
            Cerrar
          </Button>
          <Button
            variant="primary"
            onClick={handleConfirmSendAndPayLater}
            disabled={isProcessing}
            style={{ backgroundColor: isProcessing ? '#6c757d' : '#007bff' }}
          >
            {isProcessing ? 'Procesando...' : 'Confirmar'}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default OrderDetails;
